import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'
import MembersDataService from '@/services/MembersDataService'
import { Competitions } from '@/services/CompetitionsDataService'
import SysMember from '@/types/SysMember'
import { SysCompetitionResults, SysForeignCompetitionResults } from '@/types/SysCompetition'
import MatchHistoryDataService from '@/services/MatchHistoryDataService'
import SysMatchHistory from '@/types/SysMatchHistory'
import { Tournaments } from '@/services/TournamentsDataService'
import { SysTournamentMatchSettlement } from '@/types/SysTournament'
import TeamsDataService from '@/services/TeamsDataService'
import SysTeam from '@/types/SysTeam'
import ClubsDataService from '@/services/ClubsDataService'
import SysClub from '@/types/SysClub'
import SeasonsDataService from '@/services/SeasonsDataService'
import SysSaeson from '@/types/SysSaeson'

type statisticsDataType = { seasonId: number; seasonName: string; clubId: number; clubName: string; teamId: number; teamName: string; seriesId: number; seriesName: string; noMatches: number; wonMatches: number; winRatio: number; wonSet: number; totalDartScore: number; dartingForWonSet: number; oneEighty: number; highestClose: number; fastestDarting: number; setAverage: number }
type lastMatchDataType = { datePlayed: string; seriesId: number; seriesName: string; homeTeamId: null | number; homeTeamName: null | string; homeScore: number; guestTeamId: null | number; guestTeamName: null | string; guestScore: number; }
type medlemDataType = { medlemId: number; medlemStatus: boolean; brugerFornavn: string; brugerEfternavn: string; medlemLicens: number; klubNavn: string; klubSlug: string; medlemFoedselsdag: string; medlemKoen: string; medlemLicensSlut: string | null }
type dataReturnType = { error: any; medlemData: medlemDataType; lastMatchData: lastMatchDataType; statisticsData: any; }

export default class theMember extends Vue {
  private todaysDate = ''
  private memberId = this.parseURL()
  private memberSlug = 0
  private tempMedlem = {} as SysMember
  private yearPeriod = ''
  private memberResultsModal = false
  private currentResults: { date: string, competitionName: string, series: string, placement: string, points: number, foreign: boolean, competitionStart: string, competitionId: number }[] = []
  private historicalResults: { date: string, competitionName: string, series: string, placement: string, points: number, foreign: boolean, competitionStart: string, competitionId: number }[] = []
  error: any = null
  medlemData: medlemDataType = { medlemId: 0, medlemStatus: true, brugerFornavn: '', brugerEfternavn: '', medlemLicens: 0, klubNavn: '', klubSlug: '', medlemFoedselsdag: '', medlemKoen: '', medlemLicensSlut: null }
  private historicalResultsValue = 'Seneste år'
  private historicalResultsOptions: string[] = ['Seneste år']
  lastPlayedMatchHistory = {} as SysMatchHistory
  lastMatchSettlement = {} as SysTournamentMatchSettlement
  lastMatchData: lastMatchDataType = { datePlayed: '', seriesId: 0, seriesName: '', homeTeamId: null, homeTeamName: null, homeScore: 0, guestTeamId: null, guestTeamName: '', guestScore: 0 }
  tournamentHistory: SysMatchHistory[] = []
  statisticsData: statisticsDataType[] = []

  readonly name : string = 'theMember'
  data (): dataReturnType {
    return {
      medlemData: this.medlemData,
      error: this.error,
      lastMatchData: this.lastMatchData,
      statisticsData: this.statisticsData
    }
  }

  @Watch('historicalResultsValue')
  onSelectChange () : void {
    this.displayHistoricalResults()
  }

  public generateSlug (id: number, name: string, date: string) : string {
    return id.toString() + '_' + this.convertToSlug(name) + '-' + (new Date(date).toLocaleDateString('da-DK').replaceAll('.', '-'))
  }

  public havePlayerLicense (status : boolean, expirationDate: string | null) : boolean {
    if (status && expirationDate !== null && this.todaysDate <= expirationDate) {
      return true
    }

    return false
  }

  public lastYear () : string {
    const d = new Date(this.todaysDate)
    d.setDate(d.getDate() - 365)

    return d.toISOString().slice(0, 10)
  }

  public calcAge (date : string) : number {
    const ageDiff = Date.now() - new Date(date).getTime()
    const ageDate = new Date(ageDiff)

    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public displayHistoricalResults () : void {
    this.memberResultsModal = true
    this.retrieveHistoricalResults()
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public calcHighestTotal (objArray: { series: string, points: number}[]) : number {
    // In case a member have participated in different series, that awards points for different rankings, then return on only the points for the rankings with the most points.
    const menSeries = objArray.map((item, index) => {
      if (item.series.toLowerCase().includes('herre') || (item.series.toLowerCase().includes('c-rækken') && this.medlemData.medlemKoen === 'Mand')) {
        return item.points
      }

      return 0
    }).reduce((prev, curr) => prev + curr, 0)
    const womenSeries = objArray.map((item, index) => {
      if (item.series.toLowerCase().includes('dame') || (item.series.toLowerCase().includes('c-rækken') && this.medlemData.medlemKoen === 'Dame')) {
        return item.points
      }

      return 0
    }).reduce((prev, curr) => prev + curr, 0)
    const boysSeries = objArray.map((item, index) => {
      if (item.series.toLowerCase().includes('dreng') || item.series.toLowerCase().includes('boy') || item.series.toLowerCase().includes('junior')) {
        return item.points
      }

      return 0
    }).reduce((prev, curr) => prev + curr, 0)
    const girlsSeries = objArray.map((item, index) => {
      if (item.series.toLowerCase().includes('pige') || item.series.toLowerCase().includes('girl') || item.series.toLowerCase().includes('junior')) {
        return item.points
      }

      return 0
    }).reduce((prev, curr) => prev + curr, 0)
    const paraSeries = objArray.map((item, index) => {
      if (item.series.toLowerCase().includes('para')) {
        return item.points
      }

      return 0
    }).reduce((prev, curr) => prev + curr, 0)

    return Math.max(menSeries, womenSeries, boysSeries, girlsSeries, paraSeries)
  }

  public forceRerenderResultsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public async retrieveHistoricalResults () : Promise<void> {
    this.historicalResults = []
    let extraResultsParameter = `staevne_resultater_dato_gte=${this.yearPeriod}`
    let extraForeignResultsParameter = `ud_resultat_dato_gte=${this.yearPeriod}`

    if (this.historicalResultsValue !== 'Seneste år') {
      extraResultsParameter = 'staevne_resultater_dato_gte=' + this.historicalResultsValue + '-01-01&staevne_resultater_dato_lte=' + this.historicalResultsValue + '-12-31'
      extraForeignResultsParameter = 'ud_resultat_dato_gte=' + this.historicalResultsValue + '-01-01&ud_resultat_dato_lte=' + this.historicalResultsValue + '-12-31'
    }

    const resultsPromise = Competitions.CompetitionResultsDataService.getAll('', null, (extraResultsParameter + `&medlem_id.id=${this.memberId}`))
    const foreignResultsPromise = Competitions.CompetitionForeignResultsDataService.getAll('', null, (extraForeignResultsParameter + `&medlem_id.id=${this.memberId}`))

    await Promise.all([resultsPromise, foreignResultsPromise])
      .then((response) => {
        const tempResults = response[0].data as SysCompetitionResults[]
        const tempForeignResults = response[1].data as SysForeignCompetitionResults[]

        if (tempResults.length > 0) {
          for (const item of tempResults) {
            this.historicalResults.push({
              date: item.staevne_resultater_dato,
              competitionName: item.staevner_id.staevne_navn,
              series: item.staevne_raekker_id.staevne_raekker_navn,
              placement: item.staevne_resultater_placering,
              points: item.staevne_ranglistepoint,
              foreign: false,
              competitionStart: item.staevner_id.staevne_start,
              competitionId: Number(item.staevner_id.id)
            })
          }
        }
        if (tempForeignResults.length > 0) {
          for (const item of tempForeignResults) {
            this.historicalResults.push({
              date: item.ud_resultat_dato,
              competitionName: item.ud_staevner_id.ud_staevne_navn,
              series: item.staevne_raekker_id.staevne_raekker_navn,
              placement: item.ud_resultat_placering,
              points: item.ud_resultat_ranglistepoint,
              foreign: true,
              competitionStart: item.ud_staevner_id.ud_staevne_dato,
              competitionId: Number(item.ud_staevner_id.id)
            })
          }
        }
        this.historicalResults.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        console.log(this.historicalResults)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public createHistoricalOptions () : void {
    this.historicalResultsOptions = ['Seneste år']
    const curentYear = new Date().getFullYear()
    const startYear = 2010

    for (let i = 0; i < curentYear - startYear; i++) {
      const yearValue = startYear + i
      this.historicalResultsOptions.push(yearValue.toString())
    }

    this.historicalResultsOptions.sort((a, b) => {
      if (a === 'Seneste år') {
        return -1
      }
      if (b === 'Seneste år') {
        return 1
      }
      if (Number(b) < Number(a)) {
        return -1
      }
      if (Number(b) > Number(a)) {
        return 1
      }
      return 0
    })
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const memberId = parseInt(route.params.slug.toString())

    if (!isNaN(memberId)) {
      retVal = memberId
    }

    return retVal
  }

  public toMatchSchedule () : void {
    const tournamentSlug = this.lastPlayedMatchHistory.raekke_id.saeson_id.toString() + '-' + Number(this.lastPlayedMatchHistory.raekke_id.id).toString() + '_' + CommonFunctions.slugify(this.lastPlayedMatchHistory.raekke_id.raekke_navn)
    this.$router.push({ name: 'MatchSchedule', params: { tournamentSlug: tournamentSlug } })
  }

  public toMatchResults () : void {
    this.$router.push({ name: 'TournamentMatch', params: { matchId: this.lastMatchSettlement.kampprogram_id.id } })
    // this.$router.push({ name: 'TournamentMatch', params: { matchNumber: this.lastMatchSettlement.kampprogram_id.kampprogram_kampnr } })
  }

  public async retrieveMemberData () : Promise<void> {
    const datePromise = BaseFunctions.getDatetimeFromServer()
    const memberPromise = MembersDataService.get(this.memberId.toString())

    await Promise.all([datePromise, memberPromise])
      .then((response) => {
        this.todaysDate = new Date(response[0].data).toISOString().split('T')[0]
        this.tempMedlem = response[1].data

        this.medlemData = {
          medlemId: Number(this.tempMedlem.id),
          medlemStatus: this.tempMedlem.medlem_status,
          brugerFornavn: this.tempMedlem.user_id.firstname,
          brugerEfternavn: this.tempMedlem.user_id.lastname,
          medlemLicens: (this.tempMedlem.medlem_licens === null ? 0 : this.tempMedlem.medlem_licens),
          klubNavn: (this.tempMedlem.klubber_id === null ? '' : this.tempMedlem.klubber_id.klubber_klubnavn),
          klubSlug: (this.tempMedlem.klubber_id === null ? '' : this.tempMedlem.klubber_id.klubber_slug),
          medlemFoedselsdag: this.tempMedlem.medlem_foedselsdag,
          medlemKoen: this.tempMedlem.koen_id.Koen,
          medlemLicensSlut: (this.tempMedlem.medlem_licens_slut === null ? '1970-01-01' : this.tempMedlem.medlem_licens_slut)
        }
        this.yearPeriod = this.lastYear()
        this.retrieveCurrentResults()
      })
      .catch((err) => {
        console.log(err)
        this.error = err
      })
  }

  public async retrieveCurrentResults () : Promise<void> {
    this.currentResults = []
    const resultsPromise = Competitions.CompetitionResultsDataService.getAll('', null, `medlem_id.id=${this.medlemData.medlemId}&staevne_resultater_dato_gte=${this.yearPeriod}`)
    const foreignResultsPromise = Competitions.CompetitionForeignResultsDataService.getAll('', null, `medlem_id.id=${this.medlemData.medlemId}&ud_resultat_dato_gte=${this.yearPeriod}`)
    const tournamentPromise = MatchHistoryDataService.getAll('updated_at:desc', null, `medlem_id.id=${this.medlemData.medlemId}`)

    await Promise.all([resultsPromise, foreignResultsPromise, tournamentPromise])
      .then(async (response) => {
        const tempResults = response[0].data as SysCompetitionResults[]
        const tempForeignResults = response[1].data as SysForeignCompetitionResults[]
        this.tournamentHistory = response[2].data

        // Sorting and creating an array with competition results.
        if (tempResults.length > 0) {
          for (const item of tempResults) {
            this.currentResults.push({
              date: item.staevne_resultater_dato,
              competitionName: item.staevner_id.staevne_navn,
              series: item.staevne_raekker_id.staevne_raekker_navn,
              placement: item.staevne_resultater_placering,
              points: item.staevne_ranglistepoint,
              foreign: false,
              competitionStart: item.staevner_id.staevne_start,
              competitionId: Number(item.staevner_id.id)
            })
          }
        }
        if (tempForeignResults.length > 0) {
          for (const item of tempForeignResults) {
            this.currentResults.push({
              date: item.ud_resultat_dato,
              competitionName: item.ud_staevner_id.ud_staevne_navn,
              series: item.staevne_raekker_id.staevne_raekker_navn,
              placement: item.ud_resultat_placering,
              points: item.ud_resultat_ranglistepoint,
              foreign: true,
              competitionStart: item.ud_staevner_id.ud_staevne_dato,
              competitionId: Number(item.ud_staevner_id.id)
            })
          }
        }

        this.currentResults.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())

        // About tournament results
        if (this.tournamentHistory.length > 0) {
          // Get data for the last tournament match the player have played
          this.lastPlayedMatchHistory = this.tournamentHistory[0]
          try {
            this.lastMatchSettlement = (await Tournaments.TournamentMatchSettlementDataService.get(Number(this.lastPlayedMatchHistory.kampafvikling_id.id).toString())).data
          } catch (err) {
            console.log(err)
          }

          this.lastMatchData.datePlayed = new Date(this.lastMatchSettlement.kampprogram_id.kampprogram_dato).toISOString().split('T')[0]
          this.lastMatchData.homeScore = this.lastMatchSettlement.kampafvikling_resultat_hjem
          this.lastMatchData.guestScore = this.lastMatchSettlement.kampafvikling_resultat_ude
          this.lastMatchData.seriesId = Number(this.lastPlayedMatchHistory.raekke_id.id)
          this.lastMatchData.seriesName = this.lastPlayedMatchHistory.raekke_id.raekke_navn
          // Check if the member played on the home team or guest team
          if (this.lastPlayedMatchHistory.hold_id.id === this.lastMatchSettlement.kampprogram_id.kampprogram_hjemmehold) {
            // Get the name and id of the guest team.
            this.lastMatchData.homeTeamId = this.lastPlayedMatchHistory.hold_id.id
            this.lastMatchData.homeTeamName = this.lastPlayedMatchHistory.hold_id.hold_holdnavn
            try {
              const guestTeam = (await TeamsDataService.get(this.lastMatchSettlement.kampprogram_id.kampprogram_udehold.toString())).data as SysTeam
              this.lastMatchData.guestTeamId = Number(guestTeam.id)
              this.lastMatchData.guestTeamName = guestTeam.hold_holdnavn
            } catch (err) {
              console.log(err)
              this.error = err
            }
          } else {
            this.lastMatchData.guestTeamId = this.lastPlayedMatchHistory.hold_id.id
            this.lastMatchData.guestTeamName = this.lastPlayedMatchHistory.hold_id.hold_holdnavn

            try {
              const homeTeam = (await TeamsDataService.get(this.lastMatchSettlement.kampprogram_id.kampprogram_hjemmehold.toString())).data as SysTeam
              this.lastMatchData.homeTeamId = Number(homeTeam.id)
              this.lastMatchData.homeTeamName = homeTeam.hold_holdnavn
            } catch (err) {
              console.log(err)
              this.error = err
            }
          }

          // Create array for the all the tournament matches which the member have played, and calculate the statistics.
          let seasonParam = ''
          const clubIds: number[] = []
          // console.log(this.tournamentHistory)

          for (const match of this.tournamentHistory) {
            const index = this.statisticsData.findIndex(el => Number(el.seriesId) === Number(match.raekke_id.id) && Number(el.teamId) === Number(match.hold_id.id))
            clubIds.push(match.hold_id.klubber_id)
            if (index === -1) {
              seasonParam += '&id=' + match.raekke_id.saeson_id
              this.statisticsData.push({
                seasonId: match.raekke_id.saeson_id,
                seasonName: '',
                clubId: match.hold_id.klubber_id,
                clubName: '',
                teamId: Number(match.hold_id.id),
                teamName: match.hold_id.hold_holdnavn,
                seriesId: Number(match.raekke_id.id),
                seriesName: match.raekke_id.raekke_navn,
                noMatches: (match.antal_kampe === null ? 0 : match.antal_kampe),
                wonMatches: (match.antal_v_kampe === null ? 0 : match.antal_v_kampe),
                winRatio: 0,
                wonSet: (match.antal_v_saet === null ? 0 : match.antal_v_saet),
                totalDartScore: (match.sum_v_dart === null ? 0 : match.sum_v_dart),
                dartingForWonSet: 0,
                oneEighty: (match.antal_180 === null ? 0 : match.antal_180),
                highestClose: (match.hoejste_lukker === null ? 0 : match.hoejste_lukker),
                fastestDarting: (match.hurtigste_saet === null ? 1000 : match.hurtigste_saet),
                setAverage: 0
              })
            } else {
              const highestClose = match.hoejste_lukker === null ? 0 : match.hoejste_lukker
              const fastestDarting = match.hurtigste_saet === null ? 1000 : match.hurtigste_saet
              this.statisticsData[index].noMatches += (match.antal_kampe === null ? 0 : match.antal_kampe)
              this.statisticsData[index].wonMatches += (match.antal_v_kampe === null ? 0 : match.antal_v_kampe)
              this.statisticsData[index].totalDartScore += (match.sum_v_dart === null ? 0 : match.sum_v_dart)
              this.statisticsData[index].wonSet += (match.antal_v_saet === null ? 0 : match.antal_v_saet)
              this.statisticsData[index].oneEighty += (match.antal_180 === null ? 0 : match.antal_180)
              this.statisticsData[index].highestClose = highestClose > this.statisticsData[index].highestClose ? highestClose : this.statisticsData[index].highestClose
              this.statisticsData[index].fastestDarting = fastestDarting < this.statisticsData[index].fastestDarting ? fastestDarting : this.statisticsData[index].fastestDarting
            }
          }
          console.log(this.statisticsData)
          const uniqueClubIds = [...new Set(clubIds)]
          let clubParam = ''
          for (const item of uniqueClubIds) {
            clubParam += '&id=' + item
          }
          clubParam = clubParam.slice(1)
          seasonParam = seasonParam.slice(1)

          const clubPromise = ClubsDataService.getAll('', null, clubParam)
          const seasonPromise = SeasonsDataService.getAll('', null, seasonParam)

          await Promise.all([clubPromise, seasonPromise])
            .then((response) => {
              const clubs = response[0].data as SysClub[]
              const seasons = response[1].data as SysSaeson[]

              this.statisticsData.forEach(function (item) {
                const clubIndex = clubs.findIndex(el => Number(el.id) === item.clubId)
                const seasonIndex = seasons.findIndex(el => Number(el.id) === item.seasonId)
                const average = item.totalDartScore / item.wonSet
                item.clubName = (clubIndex === -1 ? '' : clubs[clubIndex].klubber_klubnavn)
                item.seasonName = (seasonIndex === -1 ? '' : seasons[seasonIndex].saeson_navn)
                item.winRatio = (item.noMatches === 0 ? 0 : (100 / item.noMatches) * item.wonMatches)
                item.dartingForWonSet = isNaN(average) ? 0 : Math.round(average * 100) / 100
                item.setAverage = isNaN(average) ? 0 : Math.round(((501 / average) * 3) * 100) / 100
              })

              console.log(this.statisticsData)
              this.statisticsData.sort((a, b) => a.seasonId - b.seasonId)
            })
            .catch((err) => {
              console.log(err)
              this.error = err
            })
        }
      })
      .catch((err) => {
        console.log(err)
        this.error = err
      })
  }

  async mounted () : Promise<void> {
    // this.parseMemberSlug()
    this.retrieveMemberData()
    this.createHistoricalOptions()
  }
}
