
import { Options, Vue } from 'vue-class-component'
import theMember from '@/components/Frontend/Member/index.vue'

@Options({
  components: {
    theMember
  }
})
export default class Memberinfo extends Vue {}
